import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NamedLink, Modal, Heading } from '../../components';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { FormattedMessage } from '../../util/reactIntl';

import css from './NewListingLink.module.css';

const NewListingLinkComponent = props => {
  const { className, onManageDisableScrolling, textId, currentUser } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isVerifiedIdentify = currentUser && get(currentUser, 'attributes.profile.publicData.isVerifiedIdentify') ? true : !currentUser;

  const notificationText = <FormattedMessage id="NewListingLink.notificationText" />;

  return (
    <>
      <span className={className} onClick={() => setIsModalOpen(true)}>
        <FormattedMessage id={textId || 'NewListingLink.newItem'} />
      </span>

      <Modal
        id="AuthenticationPage.privacyPolicy"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {isVerifiedIdentify ? (
          <div className={css.root}>

            <Heading as="h3" rootClassName={css.listTitle}>
              <FormattedMessage id="NewListingLink.title" />
            </Heading>

            <ul className={css.list}>
              <li className={css.listItem}>
                <NamedLink name="NewListingPageBoat" className={css.listItemLink}>
                  <FormattedMessage id="NewListingLink.newBoat" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="NewListingPageJetSki" className={css.listItemLink}>
                  <FormattedMessage id="NewListingLink.newJetSki" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="NewListingPageFishing" className={css.listItemLink}>
                  <FormattedMessage id="NewListingLink.newFishing" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="NewListingPageWatersport" className={css.listItemLink}>
                  <FormattedMessage id="NewListingLink.newWatersport" />
                </NamedLink>
              </li>
            </ul>
          </div>
        ) : (
          <>
            <div className={css.notificationContent}>{notificationText}</div>
            <NamedLink name="IdVerificationPage" className={css.listItemLink}>
              <FormattedMessage id="NewListingLink.continueButton" />
            </NamedLink>
          </>
        )}
      </Modal>
    </>
  );
};
NewListingLinkComponent.defaultProps = {
  className: null,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;

  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NewListingLink = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewListingLinkComponent);

export default NewListingLink;
