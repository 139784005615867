import React from 'react';
import { string } from 'prop-types';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';

import css from './TooltipInfo.module.css';

const TooltipInfo = props => {
  const { rootClassName, className, textId, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  
  if(!textId && !intl) return null;

  return (
    <div className={classes}>
      <a
        className={css.tooltip}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={intl.formatMessage({id: textId})}
      >
        i
      </a>
      <Tooltip id="my-tooltip" />
    </div>
  );
};

TooltipInfo.defaultProps = {
  rootClassName: null,
  className: null,
};

TooltipInfo.propTypes = {
  rootClassName: string,
  className: string,
};

export default TooltipInfo;
