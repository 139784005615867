import React, { Component } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { string, func, bool } from 'prop-types';
import ReactImageGallery from 'react-image-gallery';
import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import {
  propTypes,
  isBoatListingType,
  isJetSkiListingType,
  isFishingListingType,
  isWatersportListingType
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { defaultUnitType, selectDefaultPrice } from '../../util/price';
import { fakeRatingData } from '../../util/ratingHelpers';

import { AspectRatioWrapper, IconReviewStar, NamedLink, ResponsiveImage, IconArrowHead } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    comments,
    isMobileLayout
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing?.id?.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = title && createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const images = currentListing.images;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  // const variantPrefix = config.layout.listingImage.variantPrefix;

  const imageVariants = variants;
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const thumbVariants = thumbnailVariants || imageVariants;

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  const defaultPrice = selectDefaultPrice(listing);
  const unitType = defaultUnitType(listing);

  const numbersOfReview = publicData?.numbersOfReview || 0;
  const payedBookings = publicData?.payedBookings || 0;

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const authorNameLink =
    <NamedLink className={css.authorLink} name="ProfilePage" params={{ id: author?.id?.uuid }}>
      {authorName}.
    </NamedLink>


  const imageSizesMaybe = { sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px` };
  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="left" size="big" />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="right" size="big" />
        </div>
      </button>
    );
  };

  const renderItem = item => {
    return (
      <NamedLink className={css.rooLink} name="ListingPage" params={{ id, slug }}>
        <AspectRatioWrapper
          width={aspectWidth || 1}
          height={aspectHeight || 1}
          className={css.itemWrapper}
        >
          <div className={css.itemCentering}>
            <ResponsiveImage
              rootClassName={css.item}
              image={item.image}
              alt={item.alt}
              variants={imageVariants}
              {...imageSizesMaybe}
            />
          </div>
        </AspectRatioWrapper>
      </NamedLink>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  const fakeRatings = fakeRatingData(currentListing);

  return (
    <div className={classes}>

      {payedBookings > 0 && (
        <div className={css.newRental}>
          <FormattedMessage id="ListingCard.topRental" />
        </div>
      )}

      {isMobileLayout && (
        <ReactImageGallery
          additionalClass={classNames(css.productGallery, { [css.productGallerySingle]: items?.length === 1 })}
          items={items}
          renderItem={renderItem}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          showPlayButton={false}
          disableThumbnailScroll={true}
          showThumbnails={false}
          showFullscreenButton={false}
          showBullets={true}
        />
      )}

      <NamedLink className={css.rootLink} name="ListingPage" params={{ id, slug }}>
        {!isMobileLayout && (
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>
        )}

        <div className={css.info}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={classNames(css.price, css.headingContainer)}>
            <div className={css.priceContainer}>
              <span className={css.priceValue} title={priceTitle}>
                {formatMoney(intl, defaultPrice)}
              </span>
              {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
                <div className={css.perUnit}>
                  <FormattedMessage
                    id="ListingCard.perUnit"
                    values={{ unitType }}
                  />
                </div>
              ) : null}
            </div>
            <div className={css.reviewContainer}>
              {!!fakeRatings?.totalRating && (
                <div className={css.rating}>
                  <IconReviewStar className={css.star} isFilled />
                  <span>{fakeRatings?.totalRating.toFixed(1)}</span>
                </div>
              )}
              {/* {fakeRatings?.totalBookings && (
                <div>
                  ({fakeRatings?.totalBookings} <FormattedMessage id="ListingCard.bookings" />)
                </div>
              )} */}
            </div>
          </div>
        </div>
      </NamedLink>

      <div className={css.mainInfo}>
        {showAuthorInfo ? (
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.author" values={{ authorName: authorNameLink }} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
