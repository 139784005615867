import axios from 'axios';
import config from '../../config/settings';

export const sendVerification = (params) => {
  const bodyData = JSON.stringify(params);

  return axios.post(`${config.serverUrl}/api/verification/send`, bodyData, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })
};

export const checkVerification = (params) => {
  const bodyData = JSON.stringify(params);

  return axios.post(`${config.serverUrl}/api/verification/check`, bodyData, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })
};
