/**
 * Constants used for deciding component themes.
 */

/**
 * Standardised themes for input components.
 * Mirrors `styles/themes/input.css`
 * Not sure if that's the best way to do things
 * But I'll be damned copying the css over and over again.
 */
export const DEFAULT_THEME = 'DEFAULT';
export const ROUNDED_THEME = 'ROUNDED';

/**
 * This is not ideal, as we rely on the css file
 * to have a rounded and default class.
 * However, due to CSS Modules, we'd need to make a global
 * rounded/default class and import here instead of using an arg - yuck.
 */
export const getThemeClass = (theme, css) => {
  switch (theme) {
    case ROUNDED_THEME:
      return css.rounded;
    case DEFAULT_THEME:
    default:
      return css.default;
  }
}
