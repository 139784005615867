import React, { useRef, useEffect } from 'react';
import intlTelInput from 'intl-tel-input';
// import 'intl-tel-input/build/css/intlTelInput.css';
import { string, object } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { ValidationError } from '..';
import intlTelUtils from '../../util/intlTelUtils';
import { INTL_TEL_INPUT_CONFIG } from '../../marketplace-custom-config';
import { getThemeClass } from '../../util/themes';

import './intlTelInput.css';
import css from './FieldPhoneInput.module.css';

const FieldPhoneInputComponent = props => {
  const phoneInputRef = useRef(null);
  const instance = useRef(null);

  const { rootClassName, className, label, input, config, meta, theme, additionalOnChange } = props;

  const { onFocus, onBlur, value, onChange, ...rest } = input;
  const { phoneNumber } = value || {};

  useEffect(() => {
    const input = document.querySelector('#phoneTel');
    instance.current = intlTelInput(input, {
      ...config,
      utilsScript: intlTelUtils,
    });

    if (typeof value === 'string' && instance.current) {
      instance.current.setNumber(value);
    } else if (typeof value === 'object') {
      if (phoneNumber && instance.current) {
        instance.current.setNumber(phoneNumber);
      }
    }
  }, []);

  const handleBlur = () => {
    if (phoneInputRef.current.value.trim()) {
      const selectedCountry = instance.current.getSelectedCountryData();
      const { dialCode, iso2 } = selectedCountry;
      onChange({
        dialCode,
        iso2,
        phoneNumber: instance.current.getNumber(),
      });
      !!additionalOnChange &&
        additionalOnChange({
          dialCode,
          iso2,
          phoneNumber: instance.current.getNumber(),
        });
    } else {
      onChange(null);
    }
    onBlur();
  };

  const classes = classNames(rootClassName || css.root, className);
  const { valid, invalid, touched, error } = meta;
  const hasError = !!(touched && invalid && error);

  const inputClasses = classNames(css.input, getThemeClass(theme, css), {
    [css.valid]: valid,
    [css.error]: hasError,
  });

  return (
    <div className={classes}>
      {label ? <label htmlFor="phone">{label}</label> : null}
      <input
        id="phoneTel"
        type="tel"
        ref={phoneInputRef}
        className={inputClasses}
        onBlur={handleBlur}
        {...rest}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldPhoneInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  input: null,
  config: INTL_TEL_INPUT_CONFIG,
  meta: null,
};

FieldPhoneInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  label: string,
  input: object.isRequired,
  config: object,
  meta: object.isRequired,
};

const FieldPhoneInput = props => <Field component={FieldPhoneInputComponent} {...props} />;

export default FieldPhoneInput;
