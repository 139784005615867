import React from 'react';
import CustomExtendedDataField from './CustomExtendedDataField';
import { EXTENDED_DATA_SCHEMA_TYPES } from '../../util/types';
import PropTypes from 'prop-types';

const AddListingFields = props => {
  const { listingType, listingFieldsConfig, intl, wizardTab, listingCategory, minLengthValue, jetSkiQuantity } = props;
  const listingFields = listingFieldsConfig?.filter(item => item.saveConfig?.tab === wizardTab && item.saveConfig.category === listingCategory);

  const fields = listingFields.reduce((pickedFields, fieldConfig) => {
    const { key, includeForListingTypes, schemaType, scope } = fieldConfig || {};

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetProcessAlias =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);
    const isProviderScope = ['public', 'private'].includes(scope);

    return isKnownSchemaType && isProviderScope
      ? [
          ...pickedFields,
          <CustomExtendedDataField
            key={key}
            name={key}
            fieldConfig={fieldConfig}
            minLengthValue={minLengthValue}
            jetSkiQuantity={jetSkiQuantity}
            defaultRequiredMessage={intl.formatMessage({
              id: 'EditListingDetailsForm.defaultRequiredMessage',
            })}
          />,
        ]
      : pickedFields;
  }, []);

  return <>{fields}</>;
};

AddListingFields.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

AddListingFields.propTypes = {
  className: string,
  rootClassName: string,
};

export default AddListingFields;
