import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import AdvancedSearchForm from './AdvancedSearchForm';
import classNames from 'classnames';
import { fetchZoomedBounds } from '../../util/maps';
import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng, LatLngBounds } = sdkTypes;

import css from './AdvancedSearch.module.css';

const AdvancedSearch = props => {
  const { intl, topbarLayout, currentPage } = props;


  const [datesFilter, setDatesFilter] = useState({});
  const [categoryFilterOpen, setCategoryFilterOpen] = useState(false);
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);

  const updatesDatesFilter = dates => {
    setDatesFilter(dates);
  };

  const onSubmit = () => {
    return;
  };

  const getValues = values => {
    const { history, routeConfiguration } = props;

    const bounds = values?.location?.selectedPlace?.bounds;

    const searchParams = {
      address: !!values?.location?.selectedPlace?.address
        ? values?.location?.selectedPlace?.address
        : null,
      bounds: !!values?.location?.selectedPlace?.bounds
        ? fetchZoomedBounds(bounds)
        : null,
      origin: !!values?.location?.selectedPlace?.origin
        ? values?.location?.selectedPlace?.origin
        : null,

      pub_experienceType: !!values?.experienceType ? values?.experienceType : null,
      dates: !!datesFilter?.dates ? datesFilter.dates : null,
    };

    !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null

  return (
    <div className={classNames(css.root, { [css.rootTopbar]: !!topbarLayout })}>
      <h1 className={css.title}>
        <FormattedMessage id="AdvancedSearch.title" />
      </h1>
      <AdvancedSearchForm
        topbarLayout={topbarLayout}
        onSubmit={onSubmit}
        getValues={getValues}
        intl={intl}
        setCategoryFilterOpen={setCategoryFilterOpen}
        categoryFilterOpen={categoryFilterOpen}

        setTypeFilterOpen={setTypeFilterOpen}
        typeFilterOpen={typeFilterOpen}
        updatesDatesFilter={updatesDatesFilter}
        datesFilter={datesFilter}
      />
    </div>
  );
};

AdvancedSearch.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdvancedSearch);
