import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.module.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M13.028 2.001c.73-.003 1.46.005 2.19.022l.193.007c.224.008.445.018.712.03 1.064.05 1.79.218 2.427.465.66.254 1.216.598 1.772 1.154.509.5.902 1.104 1.153 1.771.247.637.415 1.364.465 2.428.012.266.022.488.03.712l.006.194c.018.73.026 1.459.023 2.188l.001.746v1.31c.003.73-.005 1.46-.023 2.189l-.006.194c-.008.224-.018.445-.03.712-.05 1.064-.22 1.79-.466 2.427a4.885 4.885 0 01-1.153 1.772c-.5.508-1.105.902-1.772 1.153-.637.247-1.363.415-2.427.465-.267.012-.488.022-.712.03l-.194.006c-.73.018-1.46.025-2.189.023l-.746.001h-1.309c-.73.003-1.46-.005-2.189-.023l-.194-.006a60.403 60.403 0 01-.712-.03c-1.064-.05-1.79-.22-2.428-.466a4.89 4.89 0 01-1.77-1.153 4.904 4.904 0 01-1.155-1.772c-.247-.637-.415-1.363-.465-2.427a74.175 74.175 0 01-.03-.712l-.005-.194c-.018-.73-.027-1.46-.025-2.189v-2.056c-.003-.73.005-1.459.022-2.188l.007-.194c.008-.224.018-.446.03-.712.05-1.065.218-1.79.465-2.428A4.88 4.88 0 013.68 3.68a4.897 4.897 0 011.77-1.155c.638-.247 1.363-.415 2.428-.465l.712-.03.194-.005c.73-.018 1.459-.027 2.188-.025l2.056.001zm-1.028 5A5 5 0 1012 17a5 5 0 000-9.999zm0 2A3 3 0 1112.001 15a3 3 0 010-6v.001zm5.25-3.5a1.25 1.25 0 000 2.498 1.25 1.25 0 000-2.5v.002z"
      ></path>
    </svg>
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
