
export const customFiltersFields = [
  {
    key: 'experienceType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: "experienceTypeJetSki", label: "Jet Ski" },
      { option: "experienceTypeBoat", label: "Boat" },
      { option: "experienceTypeYacht", label: "Yacht" },
      { option: "experienceTypeSailing", label: "Sailing" },
      { option: "experienceTypeFishing", label: "Fishing" },
      { option: "experienceTypeWatersport", label: "Watersport" }
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Activity',
      group: 'primary',
    },
  },
  {
    key: 'amenities',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      // added all options from boat, jet ski and fishing for mobile filter
      // for desktop filter options added to filter component file - SelectMultipleFilter.js
      { option: 'boatAirConditioning', label: 'Air Conditioning' },
      { option: 'boatBathroom', label: 'Bathroom' },
      { option: 'boatBarbecueGrill', label: 'Barbecue Grill' },
      { option: 'boatBluetoothAudio', label: 'Bluetooth Audio' },
      { option: 'boatDishes', label: 'Dishes' },
      { option: 'boatChildrensLifeJackets', label: "Children's Life Jackets" },
      { option: 'boatCoolerIceChest', label: 'Cooler/Ice Chest' },
      { option: 'boatFloatingMat', label: 'Floating Mat' },
      { option: 'boatFishFinder', label: 'Fish Finder' },
      { option: 'boatFishingGear', label: 'Fishing Gear' },
      { option: 'boatFishingRodHolders', label: 'Fishing Rod Holders' },
      { option: 'boatFishingWell', label: 'Fishing Well' },
      { option: 'boatHeating', label: 'Heating' },
      { option: 'boatJacuzzi', label: 'Jacuzzi' },
      { option: 'boatKyakas', label: 'Kayaks' },
      { option: 'boatKitchen', label: 'Kitchen' },
      { option: 'boatLifeJackets', label: 'Life Jackets' },
      { option: 'boatMicrowave', label: 'Microwave' },
      { option: 'boatMusicSystem', label: 'Music System' },
      { option: 'boatPaddleboards', label: 'Paddleboards' },
      { option: 'boatPetsAllowed', label: 'Pets Allowed' },
      { option: 'boatRefrigerator', label: 'Refrigerator' },
      { option: 'boatShower', label: 'Shower' },
      { option: 'boatStovetop', label: 'Stovetop' },
      { option: 'boatSunbed', label: 'Sunbed' },
      { option: 'boatSunTop', label: 'Sun Top' },
      { option: 'boatStereoAUXInput', label: 'Stereo AUX Input' },
      { option: 'boatTender', label: '  Tender' },
      { option: 'boatToilet', label: 'Toilet' },
      { option: 'boatTv', label: 'TV' },
      { option: 'boatWaterSkis', label: 'Water Skis' },
      { option: 'boatWaterSlide', label: 'Water Slide' },
      { option: 'boatWaterTube', label: 'Water Tube' },
      { option: 'boatWakeboard', label: 'Wakeboard' },
      { option: 'boatWiFi', label: 'WiFi' },
      { option: 'jetSkiBluetoothAudio', label: 'Bluetooth Audio' },
      { option: 'jetSkiBoardingLadder', label: 'Boarding Ladder' },
      { option: 'jetSkiChildrensLifeJackets', label: "Children's Life Jackets" },
      { option: 'jetSkiCoolerIceChest', label: 'Cooler/Ice Chest' },
      { option: 'jetSkiFishFinder', label: 'Fish Finder' },
      { option: 'jetSkiFishingGear', label: 'Fishing Gear' },
      { option: 'jetSkiFishingRodHolders', label: 'Fishing Rod Holders' },
      { option: 'jetSkiFishingWell', label: 'Fishing Well' },
      { option: 'jetSkiGpsNavigation', label: 'GPS Navigation' },
      { option: 'jetSkiInflatableToys', label: 'Inflatable Toys' },
      { option: 'jetSkiLcdScreen', label: 'LCD Screen' },
      { option: 'jetSkiLifeJackets', label: 'Life Jackets' },
      { option: 'jetSkiMusicSystem', label: 'Music System' },
      { option: 'jetSkiNavigation', label: 'Navigation' },
      { option: 'jetSkiSmartphoneApp', label: 'Smartphone App' },
      { option: 'jetSkiStereoAUXInput', label: 'Stereo AUX Input' },
      { option: 'jetSkiUsbPort', label: 'USB Port' },
      { option: 'jetSkiWaterSkis', label: 'Water Skis' },
      { option: 'jetSkiWaterproofStorage', label: 'Waterproof Storage' },
      { option: 'jetSkiWaterTube', label: 'Water Tube' },
      { option: 'jetSkiWakeboard', label: 'Wakeboard' },
      { option: 'fishingAirConditioning', label: 'Air Conditioning' },
      { option: 'fishingBait', label: 'Bait' },
      { option: 'fishingBathroom', label: 'Bathroom' },
      { option: 'fishingBarbecueGrill', label: 'Barbecue Grill' },
      { option: 'fishingBluetoothAudio', label: 'Bluetooth Audio' },
      { option: 'fishingChildrensLifeJackets', label: "Children's Life Jackets" },
      { option: 'fishingCoolerIceChest', label: 'Cooler/Ice Chest' },
      { option: 'fishingFightingChair', label: 'Fighting Chair' },
      { option: 'fishingFilletStation', label: 'Fillet Station' },
      { option: 'fishingFishFinder', label: 'Fish Finder' },
      { option: 'fishingFishingGear', label: 'Fishing Gear' },
      { option: 'fishingFishingRods', label: 'Fishing Rods' },
      { option: 'fishingFishingRodHolders', label: 'Fishing Rod Holders' },
      { option: 'fishingFishingWell', label: 'Fishing Well' },
      { option: 'fishingGPSNavigation', label: 'GPS Navigation' },
      { option: 'fishingHeating', label: 'Heating' },
      { option: 'fishingKitchen', label: 'Kitchen' },
      { option: 'fishingLifeJackets', label: 'Life Jackets' },
      { option: 'fishingLivewell', label: 'Livewell' },
      { option: 'fishingMicrowave', label: 'Microwave' },
      { option: 'fishingMusicSystem', label: 'Music System' },
      { option: 'fishingRefrigerator', label: 'Refrigerator' },
      { option: 'fishingShower', label: 'Shower' },
      { option: 'fishingStereoAUXInput', label: 'Stereo AUX Input' },
      { option: 'fishingStovetop', label: 'Stovetop' },
      { option: 'fishingSunTop', label: 'Sun Top' },
      { option: 'fishingTender', label: 'Tender' },
      { option: 'fishingToilet', label: 'Toilet' },
      { option: 'fishingTv', label: 'TV' },
      { option: 'fishingWiFi', label: 'WiFi' },
    ],
    filterConfig: {
      indexForSearch: true,
      searchMode: 'has_any',
      label: 'Amenities',
      group: 'primary',
    },
  },
  {
    key: 'guests',
    scope: 'public',
    schemaType: 'long',
    filterConfig: {
      indexForSearch: true,
      label: 'Passengers',
      group: 'primary',
      min: 0,
      max: 13,
      step: 1,
    },
  },  
  {
    key: 'captainPresent',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      // added all options from boat, jet ski and fishing for mobile filter
      // for desktop filter options added to filter component file - SelectMultipleFilter.js
      { option: 'captainedFilterOption,captainedOptionalFilterOption', label: 'Captained' },
      { option: 'notCaptainedFilterOption,captainedOptionalFilterOption', label: 'Not Captained' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      searchMode: 'has_any',
      label: 'Captained',
      group: 'primary',
    },
  },  
]
