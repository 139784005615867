import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  NewListingLink,
  ExternalLink
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import MenuIcon from '../MenuIcon';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const faqLink = (
    <NamedLink name="CMSPage" params={{ pageId: 'faq' }} className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.faq" />
      </span>
    </NamedLink>
  );

  const contactLink = (
    <NamedLink name="ContactUsPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.contact" />
      </span>
    </NamedLink>
  );


  const profileMenu = authenticatedOnClientSide && (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <span className={css.menuButton}>
          Menu
          <MenuIcon />
        </span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>

        <MenuItem key="HowItWorksPage">
          <NamedLink
            name="CMSPage"
            params={{ pageId: 'how-it-works' }}
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarMobileMenu.howItWorksLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="InboxPage">
          <NamedLink
            className={css.yourListingsLink}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <span className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </span>
          </NamedLink>
        </MenuItem>

        <MenuItem key="CreateListingPage">
          <span className={css.yourListingsLink}>
            <span className={css.menuItemBorder} />
            <NewListingLink />
          </span>
        </MenuItem>

        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={css.yourListingsLink}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={css.yourListingsLink}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={css.yourListingsLink}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="BlogPage">
          <ExternalLink
            href="https://www.upisle.com/boating-tips/upisle-blog/"
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.blog" />
          </ExternalLink>
        </MenuItem>

        <MenuItem key="FAQPage">
          <NamedLink
            name="CMSPage"
            params={{ pageId: 'faq' }}
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.faq" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ReferAContact">
          <NamedLink
            name="CMSPage"
            params={{ pageId: 'refer-a-contact' }}
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.referAContact" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ContactUsPage">
          <NamedLink
            name="ContactUsPage"
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.contactUs" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="PhoneNumber">
          <li className={css.listItem}>
            <a href="tel:7862323657" className={css.yourListingsLink}>
              <span className={css.menuItemBorder} />
                786-232-3657
            </a>
          </li>
        </MenuItem>


        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const noAuthenticatedMenu = !authenticatedOnClientSide && (
    <Menu>
      <MenuLabel className={css.desktopMenuLabel} isOpenClassName={css.desktopMenuIsOpen}>
        Menu
        <MenuIcon />
      </MenuLabel>
      <MenuContent className={css.desktopMenuContent}>

        <MenuItem key="HowItWorksPage">
          <NamedLink
            name="CMSPage"
            params={{ pageId: 'how-it-works' }}
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarMobileMenu.howItWorksLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="CreateListingPage">
          <span className={css.yourListingsLink}>
            <span className={css.menuItemBorder} />
            <NewListingLink />
          </span>
        </MenuItem>

        <MenuItem key="BlogPage">
          <ExternalLink
            href="https://www.upisle.com/boating-tips/upisle-blog/"
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.blog" />
          </ExternalLink>
        </MenuItem>

        <MenuItem key="FAQPage">
          <NamedLink
            name="CMSPage"
            params={{ pageId: 'faq' }}
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.faq" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ContactUsPage">
          <NamedLink
            name="ContactUsPage"
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.contactUs" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="PhoneNumber">
          <li className={css.listItem}>
            <a href="tel:7862323657" className={css.yourListingsLink}>
              <span className={css.menuItemBorder} />
                786-232-3657
            </a>
          </li>
        </MenuItem>

        {/* <MenuItem key="SignupPage">
          <NamedLink
            name="SignupPage"
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.signup" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="LoginPage">
          <NamedLink
            name="LoginPage"
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.login" />
          </NamedLink>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {search}
      {noAuthenticatedMenu}
      {profileMenu}
      {authenticatedOnClientSide && <Avatar className={css.avatar} user={currentUser} disableProfileLink />}
      {/* <span className={css.createListingLink}>
        <NewListingLink className={css.createListing} />
      </span>
      {faqLink}
      {contactLink}
      {inboxLink}
    */}
      {loginLink}
      {signupLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
