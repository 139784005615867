import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTumblr.module.css';

const IconSocialMediaTumblr = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#fff"
        d="M4.244 0A4.235 4.235 0 000 4.244v13.512A4.235 4.235 0 004.244 22h13.512A4.235 4.235 0 0022 17.756V4.244A4.235 4.235 0 0017.756 0H4.244zm5.325 3.11h2.18v3.877h3.64v2.408h-3.64v3.932c0 .889.048 1.46.142 1.713.094.252.27.453.525.604.34.204.727.305 1.164.305.776 0 1.549-.252 2.317-.757v2.418c-.655.309-1.248.527-1.78.65a7.565 7.565 0 01-1.723.187c-.7 0-1.32-.09-1.86-.266a4.009 4.009 0 01-1.378-.757c-.38-.328-.644-.676-.791-1.046-.147-.369-.22-.904-.22-1.604v-5.38H6.449v-2.17a4.5 4.5 0 001.549-.841 4.2 4.2 0 001.034-1.317c.26-.512.439-1.164.537-1.955z"
      ></path>
    </svg>
  );
};

IconSocialMediaTumblr.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTumblr.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTumblr;
