import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaTikTok,
  IconSocialMediaYoutube,
  IconSocialMediaPinterest,
  IconSocialMediaTumblr,
  Logo,
  ExternalLink,
  NamedLink,
  NewListingLink
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = (intl, config) => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    siteTikTokPage,
    siteYoutubePage,
    sitePinterestPage,
    siteTumblrPage
  } = config;

  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const goToTikTok = intl.formatMessage({ id: 'Footer.goToTikTok' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });
  const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });
  const goToTumblr = intl.formatMessage({ id: 'Footer.goToTumblr' });


  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const tikTokLink = siteTikTokPage ? (
    <ExternalLink
      key="linkToTikTok"
      href={siteTikTokPage}
      className={css.icon}
      title={goToTikTok}
    >
      <IconSocialMediaTikTok />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.icon}
      title={goToYoutube}
    >
      <IconSocialMediaYoutube />
    </ExternalLink>
  ) : null;

  const pinterestLink = sitePinterestPage ? (
    <ExternalLink
      key="linkToPinterest"
      href={sitePinterestPage}
      className={css.icon}
      title={goToPinterest}
    >
      <IconSocialMediaPinterest />
    </ExternalLink>
  ) : null;

  const tumblrLink = siteTumblrPage ? (
    <ExternalLink
      key="linkToTumblr"
      href={siteTumblrPage}
      className={css.icon}
      title={goToTumblr}
    >
      <IconSocialMediaTumblr />
    </ExternalLink>
  ) : null;

  return [instragramLink, fbLink, tikTokLink, pinterestLink, twitterLink, youtubeLink, tumblrLink].filter(v => v != null);
};

const Footer = props => {
  const config = useConfiguration();
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl, config);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>

      <div className={css.content}>
        <div className={css.organization} id="organization">
          <NamedLink name="LandingPage" className={css.logoLink}>
            <Logo className={css.logo} />
          </NamedLink>
          <p className={css.organizationCopyright}>
            <NamedLink name="LandingPage" className={css.copyrightLink}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
          </p>
        </div>
        <div className={css.infoLinks}>
          <ul className={css.list}>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'how-it-works' }} className={css.link}>
                <FormattedMessage id="Footer.toHowItWorksPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NewListingLink className={css.createListing} />
            </li>
            <li className={css.listItem}>
              <NamedLink name="LoginPage" className={css.link}>
                <FormattedMessage id="Footer.toLoginPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'faq' }} className={css.link}>
                <FormattedMessage id="Footer.toFAQPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="ContactUsPage" className={css.link}>
                <FormattedMessage id="Footer.toContactPage" />
              </NamedLink>
            </li>
          </ul>
        </div>

        <div className={css.infoLinks}>
          <ul className={css.list}>
            <li className={css.listItem}>
              <NamedLink name="CMSPage" params={{ pageId: 'about' }} className={css.link}>
                <FormattedMessage id="Footer.toAboutPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="TermsOfServicePage" className={css.link}>
                <FormattedMessage id="Footer.termsOfUse" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="PrivacyPolicyPage" className={css.link}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <ExternalLink href="https://www.upisle.com/boating-tips/upisle-blog/" className={css.link}>
                <FormattedMessage id="Footer.blog" />
              </ExternalLink>
            </li>
            <li className={css.listItem}>
              <a href="tel:7862323657" className={css.link}>
                786-232-3657
              </a>
            </li>
          </ul>
        </div>

        <div className={css.socialLinks}>
          <NamedLink
            name="CMSPage"
            params={{ pageId: 'refer-a-contact' }}
            className={classNames(css.link, css.referAContactlink)}
          >
            <FormattedMessage id="Footer.referAContact" />
          </NamedLink>
          <h3 className={css.socialLinksTitle}>
            <FormattedMessage id="Footer.socialMedia" />
          </h3>
          {socialMediaLinks}
        </div>
      </div>

      <p className={css.organizationCopyrightMobile}>
        <NamedLink name="LandingPage" className={css.copyrightLink}>
          <FormattedMessage id="Footer.copyright" />
        </NamedLink>
      </p>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
