import { get } from 'lodash';
import { types as sdkTypes } from './sdkLoader';
import defaultConfig from '../config/configDefault';

const { Money } = sdkTypes;

const CURRENCY = defaultConfig.currency;

const TYPE_DAY = 'day';
const TYPE_HOUR = 'hour';

export const selectDefaultPrice = listing => {
  if (!listing) return new Money(0, CURRENCY);

  const { pricePerHour, pricePerDay } = get(listing, 'attributes.publicData', {});
  const price = get(listing, 'attributes.price');

  if (!pricePerHour && !pricePerDay) return price;

  return pricePerHour ? new Money(pricePerHour, CURRENCY) : new Money(pricePerDay, CURRENCY);
};

export const defaultUnitType = listing => {
  if (!listing) return TYPE_HOUR;

  const { pricePerHour, pricePerDay, unitType } = get(listing, 'attributes.publicData', {});
  if (!pricePerHour && !pricePerDay) return unitType;

  return pricePerHour ? TYPE_HOUR : TYPE_DAY;
};
