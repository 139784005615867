import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconMessage.module.css';

const IconMessage = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_12_528)">
        <path
          stroke="#45A8D4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12.003 1a10.994 10.994 0 019.69 5.806 11.002 11.002 0 01-.537 11.286L23 23l-6.175-1.117a10.995 10.995 0 01-15.543-7.408A11.003 11.003 0 017.223 2.09 10.994 10.994 0 0112.003 1z"
        ></path>
        <circle
          cx="2"
          cy="2"
          r="2"
          fill="#45A8D4"
          transform="matrix(-1 0 0 1 9 10)"
        ></circle>
        <circle
          cx="2"
          cy="2"
          r="2"
          fill="#45A8D4"
          transform="matrix(-1 0 0 1 14 10)"
        ></circle>
        <circle
          cx="2"
          cy="2"
          r="2"
          fill="#45A8D4"
          transform="matrix(-1 0 0 1 19 10)"
        ></circle>
      </g>
      <defs>
        <clipPath id="clip0_12_528">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="matrix(-1 0 0 1 24 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconMessage.defaultProps = {
  rootClassName: null,
  className: null,
};

IconMessage.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMessage;
