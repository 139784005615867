import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_BOAT,
  LISTING_JET_SKI,
  LISTING_FISHING,
  LISTING_WATERSPORT
} from '../../util/types';
import {
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  FieldLocationAutocompleteInput,
  Button,
  FieldCheckbox,
  IconSearch,
  OutsideClickHandler
} from '../../components';
import BookingDateRangeFilter from '../../containers/SearchPage/BookingDateRangeFilter/BookingDateRangeFilter';

import css from './AdvancedSearch.module.css';

const identity = v => v;

export const AdvancedSearchForm = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        onSubmit,
        getValues,
        categoryFilterOpen,
        setCategoryFilterOpen,
        form,
        updatesDatesFilter,
        datesFilter,
      } = formRenderProps;

      const clearExperienceTypeValues = () => {
        form.change('experienceType', undefined);
        setCategoryFilterOpen(false);
      };

      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const listingCategoryOptions = [
        { key: "experienceTypeJetSki", label: "Jet Ski" },
        { key: "experienceTypeBoat", label: "Boat" },
        { key: "experienceTypeYacht", label: "Yacht" },
        { key: "experienceTypeSailing", label: "Sailing" },
        { key: "experienceTypeFishing", label: "Fishing" },
        { key: "experienceTypeWatersport", label: "Watersport" }
      ]

      const currentLocationSelected = !!values?.location?.selectedPlace?.origin;
      const datesFilterSelected = !!datesFilter?.dates;
      const categoryFilterSelected = values?.experienceType?.length;


      const selectedCategories = !!values?.experienceType &&
        listingCategoryOptions.filter(cat => values?.experienceType.find(i => i == cat.key)).map(c => c.label);

      return (
        <Form className={css.rootForm} onSubmit={(e) => { e.preventDefault(), onSubmit }}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldLocationAutocompleteInput
            rootClassName={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus={false}
            name="location"
            label={intl.formatMessage({ id: 'AdvancedSearch.placeOfDeparture' })}
            placeholder={intl.formatMessage({
              id: 'EditListingLocationForm.addressPlaceholder',
            })}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          <div className={classNames(css.dates, { [css.datesActive]: datesFilterSelected })}>
            <label htmlFor="dates">
              <FormattedMessage id="AdvancedSearch.dates" />
            </label>
            <BookingDateRangeFilter
              id='dates'
              name='dates'
              label="MM/DD/YYYY"
              queryParamNames={["dates"]}
              initialValues={datesFilter}
              onSubmit={(values) => updatesDatesFilter(values)}
              showAsPopup
              showLabelIcon
            />
          </div>

          <OutsideClickHandler onOutsideClick={() => setCategoryFilterOpen(false)} className={css.category}>
            <div className={classNames({ [css.categoryOpen]: categoryFilterOpen }, { [css.categoryClosed]: !categoryFilterOpen })}>
              <label htmlFor="experienceType">
                <FormattedMessage id="AdvancedSearch.categoryLabel" />
              </label>
              <div
                onClick={() => setCategoryFilterOpen(!categoryFilterOpen)}
                className={classNames(css.categoryMenuLabel, { [css.categoryMenuLabelActive]: categoryFilterSelected })}
              >
                {categoryFilterSelected ? selectedCategories?.join(", ") : <FormattedMessage id="AdvancedSearch.categoryPlaceholder" />}
              </div>

              <div className={css.categoryMenuContent}>

                <div className={css.categoryItems}>
                  {listingCategoryOptions.map(c => {
                    return (
                      <FieldCheckbox
                        className={css.categoryItem}
                        key={c.key}
                        id={c.key}
                        name="experienceType"
                        label={c.label}
                        value={c.key}
                      />
                    )
                  })}
                </div>
                <div className={css.categoryButtons}>
                  <span onClick={clearExperienceTypeValues} className={css.categoryButtonsItem}>
                    <FormattedMessage id="AdvancedSearch.clear" />
                  </span>
                  <span>
                    <span onClick={() => setCategoryFilterOpen(false)} className={css.categoryButtonsItem}>
                      <FormattedMessage id="AdvancedSearch.cancel" />
                    </span>
                    <span onClick={() => setCategoryFilterOpen(false)} className={css.categoryButtonsItem}>
                      <FormattedMessage id="AdvancedSearch.apply" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </OutsideClickHandler>

          <Button
            className={css.submitButton}
            type="button"
            inProgress={submitInProgress}
            ready={submitReady}
            onClick={() => getValues(values)}
          >
            <FormattedMessage id="AdvancedSearch.search" />
          </Button>
        </Form>
      );
    }}
  />
);

export default compose(injectIntl)(AdvancedSearchForm);
