import { LISTING_JET_SKI } from '../util/types';

import { locatedOptions } from './configListingOptions'

export const jetSkiFields = [
  {
    key: 'jetSkiType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'recreation', label: 'Recreation' },
      { option: 'touring', label: 'Touring' },
      { option: 'performance', label: 'Performance' },
      { option: 'towSports', label: 'Tow Sports' },
      { option: 'sportFishing', label: 'Sport Fishing' },
      { option: 'standUpJetSki', label: 'Stand Up Jet Ski' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Jet Ski Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Jet Ski Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Jet Ski Type',
      placeholderMessage: 'Select a jet ski type',
      isRequired: true,
      requiredMessage: 'You need to select a jet ski type.',
      tab: 'details',
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'jetSkiMake',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Jet Ski Make',
      group: 'primary',
    },
    showConfig: {
      label: 'Jet Ski Make',
      isDetail: true,
    },
    saveConfig: {
      label: 'Jet Ski Make',
      placeholderMessage: 'Enter the make of your jet ski',
      isRequired: false,
      requiredMessage: 'You need to enter a make of your jet ski',
      tab: 'details',
      isSimpleTextField: true,
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'jetSkiModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Jet Ski Model',
      group: 'primary',
    },
    showConfig: {
      label: 'Jet Ski Model',
      isDetail: true,
    },
    saveConfig: {
      label: 'Jet Ski Model',
      placeholderMessage: 'Enter the model of your jet ski',
      isRequired: false,
      requiredMessage: 'You need to enter a model of your jet ski',
      tab: 'details',
      isSimpleTextField: true,
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'jetSkiYear',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Jet Ski Year',
      group: 'primary',
    },
    showConfig: {
      label: 'Jet Ski Year',
      isDetail: true,
    },
    saveConfig: {
      label: 'Jet Ski Year',
      placeholderMessage: 'Enter the year of your jet ski',
      isRequired: false,
      requiredMessage: 'You need to enter a year of your jet ski',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'jetSkiPassengerCapacity',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Jet Ski Passenger Capacity',
      group: 'primary',
    },
    showConfig: {
      label: 'Jet Ski Passenger Capacity',
      isDetail: true,
    },
    saveConfig: {
      label: 'Jet Ski Passenger Capacity',
      placeholderMessage: 'Maximum jet ski capacity',
      isRequired: true,
      requiredMessage: 'You need to enter a maximum jet ski capacity',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_JET_SKI,
    },
  },
  // {
  //   key: 'jetSkiQuantity',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '1', label: '1' },
  //     { option: '2', label: '2' },
  //     { option: '3', label: '3' },
  //     { option: '4', label: '4' },
  //     { option: '5', label: '5' },
  //     { option: '6', label: '6' },
  //     { option: '7', label: '7' },
  //     { option: '8', label: '8' },
  //     { option: '9', label: '9' },
  //     { option: '10', label: '10' },
  //     { option: '11', label: '11' },
  //     { option: '12', label: '12' },
  //     { option: '13', label: '13' },
  //     { option: '14', label: '14' },
  //     { option: '15', label: '15' },
  //     { option: '16', label: '16' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: false,
  //     label: 'Jet Ski Quantity',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Jet Ski Quantity',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Jet Ski Quantity',
  //     placeholderMessage: 'Select # of Jet skis available',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a number of Jet skis available.',
  //     tab: 'details',
  //     category: LISTING_JET_SKI,
  //   },
  // },
  {
    key: 'abilityRentMultipleJetSkis',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '1', label: '1' },
      { option: '2', label: '2' },
      { option: '3', label: '3' },
      { option: '4', label: '4' },
      { option: '5', label: '5' },
      { option: '6', label: '6' },
      { option: '7', label: '7' },
      { option: '8', label: '8' },
      { option: '9', label: '9' },
      { option: '10', label: '10' },
      { option: '11', label: '11' },
      { option: '12', label: '12' },
      { option: '13', label: '13' },
      { option: '14', label: '14' },
      { option: '15', label: '15' },
      { option: '16', label: '16' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Ability to Rent Multiple Items',
      group: 'primary',
    },
    showConfig: {
      label: 'Ability to Rent Multiple Items',
      isDetail: true,
    },
    saveConfig: {
      label: 'Ability to Rent Multiple Items',
      placeholderMessage: 'Set ability to rent multiple items',
      isRequired: false,
      requiredMessage: 'You need to enter a ability to rent multiple items',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'listingLocatedAtJetSki',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: locatedOptions,
    filterConfig: {
      indexForSearch: false,
      label: 'Jet Ski is located at:',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'Jet Ski is located at:',
    },
    saveConfig: {
      label: 'Jet Ski is located at:',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select at least one option.',
      tab: 'location',
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'pickupLocationDescriptionJetSki',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Pickup Location Description',
      group: 'primary',
    },
    showConfig: {
      label: 'Pickup Location Description',
      isDetail: true,
    },
    saveConfig: {
      label: 'Pickup Location Description',
      placeholderMessage: 'To ensure timely departures, please provide detailed location instructions for your renter to find the location of you rental experience.',
      isRequired: true,
      requiredMessage: 'You need to enter a pickup location description',
      tab: 'location',
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'dropOffLocationDescriptionJetSki',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Drop off Location Description (optional)',
      group: 'primary',
    },
    showConfig: {
      label: 'Drop off Location Description (optional)',
      isDetail: true,
    },
    saveConfig: {
      label: 'Drop off Location Description (optional)',
      placeholderMessage: 'If you are offering renters alternative drop off locations outside of the original pickup location, please describe where those drop off location options are in detail.',
      tab: 'location',
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'destinationDescriptionJetSki',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Destination Description',
      group: 'primary',
    },
    showConfig: {
      label: 'Destination Description',
      isDetail: true,
    },
    saveConfig: {
      label: 'Destination Description',
      placeholderMessage: 'Describe the destinations & sights renters will experience (i.e. Open Ocean, Sandbars, Mansion Tours, City Views, Islands, etc.)',
      tab: 'location',
      isRequired: true,
      requiredMessage: 'You need to enter a destination description',
      category: LISTING_JET_SKI,
    },
  },
  {
    key: 'listingAmenitiesJetSki',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'jetSkiBluetoothAudio', label: 'Bluetooth Audio' },
      { option: 'jetSkiBoardingLadder', label: 'Boarding Ladder' },
      { option: 'jetSkiChildrensLifeJackets', label: "Children's Life Jackets" },
      { option: 'jetSkiCoolerIceChest', label: 'Cooler/Ice Chest' },
      { option: 'jetSkiFishFinder', label: 'Fish Finder' },
      { option: 'jetSkiFishingGear', label: 'Fishing Gear' },
      { option: 'jetSkiFishingRodHolders', label: 'Fishing Rod Holders' },
      { option: 'jetSkiFishingWell', label: 'Fishing Well' },
      { option: 'jetSkiGpsNavigation', label: 'GPS Navigation' },
      { option: 'jetSkiInflatableToys', label: 'Inflatable Toys' },
      { option: 'jetSkiLcdScreen', label: 'LCD Screen' },
      { option: 'jetSkiLifeJackets', label: 'Life Jackets' },
      { option: 'jetSkiMusicSystem', label: 'Music System' },
      { option: 'jetSkiNavigation', label: 'Navigation' },
      { option: 'jetSkiSmartphoneApp', label: 'Smartphone App' },
      { option: 'jetSkiStereoAUXInput', label: 'Stereo AUX Input' },
      { option: 'jetSkiUsbPort', label: 'USB Port' },
      { option: 'jetSkiWaterSkis', label: 'Water Skis' },
      { option: 'jetSkiWaterproofStorage', label: 'Waterproof Storage' },
      { option: 'jetSkiWaterTube', label: 'Water Tube' },
      { option: 'jetSkiWakeboard', label: 'Wakeboard' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Jet Ski Amenities',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'Jet Ski Amenities',
    },
    saveConfig: {
      label: ' ',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select at least one option.',
      tab: 'amenities',
      twoColumns: true,
      category: LISTING_JET_SKI,
    },
  },
]
