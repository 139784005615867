import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';

import css from './ReferAContact.module.css';

export const ReferAContactComponent = props => {
  const { currentUser, isAuthenticated } = props;

  const routeConfiguration = useRouteConfiguration();
  const location = useLocation();
  const history = useHistory();

  const [isCopied, setIsCopied] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true)
  }, [isAuthenticated])

  const currentUserId = currentUser?.id?.uuid;

  const rootUrl = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
  let pathname = `${rootUrl}/signup?ref=${currentUserId}`;

  const copyLink = () => {
    if (typeof window === undefined) {
      return;
    }
    window.navigator.clipboard.writeText(pathname);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  if (isMounted && !isAuthenticated && !currentUser) {
    const state = { from: `${location.pathname}${location.search}${location.hash}` };
    // signup and return back to this page.
    history.push(createResourceLocatorString('SignupPage', routeConfiguration, {}, {}), state);
  };

  return (
    <div className={css.root}>
      <div className={css.copyBlock}>
        {!!pathname && <p className={css.copyBlockLink}>{pathname.slice(0, 60)}...</p>}
        <Button type="button" onClick={copyLink} className={css.copyBlockButton}>
          {isCopied ? (
            <FormattedMessage id="ReferAContact.copiedLink" />
          ) : (
            <FormattedMessage id="ReferAContact.copyLink" />
          )}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {

  const { currentUser } = state.user;
  const { isAuthenticated } = state.auth;
  
  return { currentUser, isAuthenticated };
};

const ReferAContact = compose(
  connect(mapStateToProps),
  withRouter,
)(ReferAContactComponent);

export default ReferAContact;
