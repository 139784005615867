import React from 'react';
import Select, { components } from 'react-select';

import css from './DurationField.module.css';

const Option = props => {
  const { children, isSelected, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      css={{ border: '1px solid red', padding: '15px' }}
      style={{
        display: 'inline-block',
        width: props.options[0].options.length === 1 ? '100%' : '50%',
        margin: '10px 0',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
      {...innerProps}
    >
      <div
        style={{
          textAlign: 'center',
          border: '1px solid #d8dce6',
          width: 'fit-content',
          margin: '0 auto',
          padding: '5px 30px 5px 30px',
          background: isSelected ? '#45a8d4' : 'transparent',
          color: isSelected ? 'white' : 'black',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const Group = props => (
  <div style={{ padding: '0 15px' }}>
    <components.Group {...props} />
  </div>
);

const colourStyles = {
  control: (styles, { isDisabled }) => {
    // return { ...styles, backgroundColor: isDisabled ? 'transparent' : 'white' };
    return { ...styles, backgroundColor: 'transparent'};
  },
  option: styles => {
    return {
      ...styles,
      cursor: 'pointer',
    };
  },
};

const DurationField = ({ onChange, options = [], isDisabled, ...props }) => {
  const durationOptions = options?.length
    ? [{ label: '', options: options.sort((a, b) => a.value - b.value) }]
    : [];

  return (
    <div>
      <span className={css.durationLabel}>Duration</span>
      <Select
        {...props}
        id={'duration'}
        name={'duration'}
        options={durationOptions}
        components={{ Group, Option }}
        onChange={onChange}
        isDisabled={isDisabled}
        placeholder={'Select duration'}
        styles={colourStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'black',
          },
        })}
      />
    </div>
  );
};

export default DurationField;
