import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl, isPartialRefund } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const totalPrice = isProvider
    ? {...transaction.attributes.payoutTotal}
    : {...transaction.attributes.payinTotal};

  const marketplaceCommission= transaction.attributes.payinTotal.amount - transaction.attributes.payoutTotal.amount;
  const amountCustomer = isPartialRefund ? totalPrice.amount / 2 : totalPrice.amount;
  const amountProvider = isPartialRefund ? transaction.attributes.payinTotal.amount / 2 - marketplaceCommission : totalPrice.amount;

  const formattedTotalPrice = formatMoney(intl, new Money(isProvider ? amountProvider : amountCustomer, totalPrice.currency));

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
