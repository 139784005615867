import { LISTING_WATERSPORT } from '../util/types';

import { locatedOptions } from './configListingOptions'

export const watersportFields = [
  {
    key: 'watersportType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Bodyboarding', label: 'Bodyboarding' },
      { option: 'Canoeing', label: 'Canoeing' },
      { option: 'Flyboarding', label: 'Flyboarding' },
      { option: 'Inflatable Toys', label: 'Inflatable Toys' },
      { option: 'Kayaking', label: 'Kayaking' },
      { option: 'Kitesurfing', label: 'Kitesurfing' },
      { option: 'Kneeboarding', label: 'Kneeboarding' },
      { option: 'Paddleboarding', label: 'Paddleboarding' },
      { option: 'Parasailing', label: 'Parasailing' },
      { option: 'Pedal Boat', label: 'Pedal Boat' },
      { option: 'Rafting', label: 'Rafting' },
      { option: 'Scuba Diving', label: 'Scuba Diving' },
      { option: 'Seabobbing', label: 'Seabobbing' },
      { option: 'Seascooter', label: 'Seascooter' },
      { option: 'Skimboarding', label: 'Skimboarding' },
      { option: 'Snorkeling', label: 'Snorkeling' },
      { option: 'Surfing', label: 'Surfing' },
      { option: 'Tiki Boat', label: 'Tiki Boat' },
      { option: 'Tubing', label: 'Tubing' },
      { option: 'Wakeboarding', label: 'Wakeboarding' },
      { option: 'Waterskiing', label: 'Waterskiing' },
      { option: 'Windsurfing', label: 'Windsurfing' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Watersport Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Watersport Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Watersport Type',
      placeholderMessage: 'Select a watersport type',
      isRequired: true,
      requiredMessage: 'You need to select a watersport type.',
      tab: 'details',
      category: LISTING_WATERSPORT,
    },
  },
  {
    key: 'abilityRentMultipleWatersports',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '1', label: '1' },
      { option: '2', label: '2' },
      { option: '3', label: '3' },
      { option: '4', label: '4' },
      { option: '5', label: '5' },
      { option: '6', label: '6' },
      { option: '7', label: '7' },
      { option: '8', label: '8' },
      { option: '9', label: '9' },
      { option: '10', label: '10' },
      { option: '11', label: '11' },
      { option: '12', label: '12' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Ability to Rent Multiple Items',
      group: 'primary',
    },
    showConfig: {
      label: 'Ability to Rent Multiple Items',
      isDetail: true,
    },
    saveConfig: {
      label: 'Ability to Rent Multiple Items',
      placeholderMessage: 'Set ability to rent multiple items',
      isRequired: false,
      requiredMessage: 'You need to enter a ability to rent multiple items',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_WATERSPORT,
    },
  },
  {
    key: 'listingLocatedAtWatersport',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: locatedOptions,
    filterConfig: {
      indexForSearch: false,
      label: 'Watersport is located at:',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'Watersport is located at:',
    },
    saveConfig: {
      label: 'Watersport is located at:',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select at least one option.',
      tab: 'location',
      category: LISTING_WATERSPORT,
    },
  },
  {
    key: 'pickupLocationDescriptionWatersport',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Pickup Location Description',
      group: 'primary',
    },
    showConfig: {
      label: 'Pickup Location Description',
      isDetail: true,
    },
    saveConfig: {
      label: 'Pickup Location Description',
      placeholderMessage: 'To ensure timely departures, please provide detailed location instructions for your renter to find the location of you rental experience.',
      isRequired: true,
      requiredMessage: 'You need to enter a pickup location description',
      tab: 'location',
      category: LISTING_WATERSPORT,
    },
  },
  {
    key: 'dropOffLocationDescriptionWatersport',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Drop off Location Description (optional)',
      group: 'primary',
    },
    showConfig: {
      label: 'Drop off Location Description (optional)',
      isDetail: true,
    },
    saveConfig: {
      label: 'Drop off Location Description (optional)',
      placeholderMessage: 'If you are offering renters alternative drop off locations outside of the original pickup location, please describe where those drop off location options are in detail.',
      tab: 'location',
      category: LISTING_WATERSPORT,
    },
  },
  {
    key: 'destinationDescriptionWatersport',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Destination Description',
      group: 'primary',
    },
    showConfig: {
      label: 'Destination Description',
      isDetail: true,
    },
    saveConfig: {
      label: 'Destination Description',
      placeholderMessage: 'Describe the destinations & sights renters will experience (i.e. Open Ocean, Sandbars, Mansion Tours, City Views, Islands, etc.)',
      tab: 'location',
      isRequired: true,
      requiredMessage: 'You need to enter a destination description',
      category: LISTING_WATERSPORT,
    },
  },
]
