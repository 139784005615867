import {
  propTypes,
  isBoatListingType,
  isJetSkiListingType,
  isFishingListingType,
  isWatersportListingType
} from './types';

import { defaultUnitType, selectDefaultPrice } from './price';

export const fakeRatingData = (listing) => {

  const { price, publicData = {} } = listing.attributes;

  const defaultPrice = selectDefaultPrice(listing);
  const unitType = defaultUnitType(listing);

  const listingCategory = publicData?.listingCategory;

  const isBoatListing = isBoatListingType(listingCategory);
  const isJetSkiListing = isJetSkiListingType(listingCategory);
  const isFishingListing = isFishingListingType(listingCategory);
  const isWatersportListing = isWatersportListingType(listingCategory);

  const boatLength = publicData?.boatLength;
  const boatType = publicData?.boatType;

  const isYachtListing = (+boatLength >= 40 && (boatType === "boat" || boatType === "catamaran")) || boatType === "yachtOver40ft";

  const priceAmount = price?.amount/100;

  let hardcodedRating = 5;

  // if (isBoatListing) {
  //   hardcodedRating = 4.9;
  // } else if (isWatersportListing || isFishingListing) {
  //   hardcodedRating = 4.9;
  // } else if (isJetSkiListing) {
  //   hardcodedRating = 5.0;
  // } else {
  //   hardcodedRating = 4.8;
  // }

  const customReview = publicData?.customReview;
  const numbersOfReview = publicData?.numbersOfReview || 0;

  const fakeRating = customReview && (customReview.reduce(function (acc, obj) { return acc + obj.rating; }, 0)) || 0
  const fakeRatingCount = customReview?.length || 0;

  const currentRating = publicData?.currentRating || 0;
  const ratingTotal = publicData?.ratingTotal || 0;

  let totalRating = (!fakeRating && currentRating) || (fakeRating+ratingTotal)/(numbersOfReview+fakeRatingCount) || hardcodedRating;


  // if(totalRating < 4) {
  //   totalRating = 4;
  // }

  const commentsCount = !!numbersOfReview ? numbersOfReview+fakeRatingCount : fakeRatingCount;
  const someNumberFromPrice = +defaultPrice?.amount?.toString()?.slice(0, 1);

  let bookingsCount
  if (commentsCount === 0 && (isBoatListing || isFishingListing || isWatersportListing)) {
    bookingsCount = 37 + someNumberFromPrice;
  } else if (commentsCount !== 0) {
    bookingsCount = 45 + someNumberFromPrice + commentsCount;
  } else {
    bookingsCount = 38 + someNumberFromPrice;
  }


  let jetSkiBookingsCount
  if (priceAmount <= 40) {
    jetSkiBookingsCount = 124 + someNumberFromPrice + commentsCount;
  } 
  else if (priceAmount > 40 && priceAmount <= 100) {
    jetSkiBookingsCount = 111 + someNumberFromPrice + commentsCount;
  }
  else if (priceAmount > 100 && priceAmount <= 150) {
    jetSkiBookingsCount = 98 + someNumberFromPrice + commentsCount;
  } else {
    jetSkiBookingsCount = 102 + someNumberFromPrice + commentsCount;
  }


  let yachtsBookingsCount
  if (priceAmount <= 999) {
    yachtsBookingsCount = 22 + someNumberFromPrice + commentsCount;
  } 
  else if (priceAmount >= 1000 && priceAmount <= 3999) {
    yachtsBookingsCount = 18 + someNumberFromPrice + commentsCount;
  }
  else if (priceAmount >= 4000 && priceAmount <= 7999) {
    yachtsBookingsCount = 14 + someNumberFromPrice + commentsCount;
  } 
  else if (priceAmount >= 8000 && priceAmount <= 9999) {
    yachtsBookingsCount = 8 + someNumberFromPrice + commentsCount;
  } 
  else if (priceAmount >= 10000) {
    yachtsBookingsCount = 6 + someNumberFromPrice + commentsCount;
  } 
  else {
    yachtsBookingsCount = 4 + someNumberFromPrice + commentsCount;
  }

  const totalBookings = isYachtListing ? yachtsBookingsCount : isJetSkiListing ? jetSkiBookingsCount : bookingsCount;

  return {
    totalRating,
    totalBookings,
  }
};

