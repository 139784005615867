import { LISTING_BOAT } from '../util/types';

import { locatedOptions } from './configListingOptions'

export const boatFields = [
  {
    key: 'boatType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'boat', label: 'Boat' },
      { option: 'pontoon', label: 'Pontoon' },
      { option: 'yachtOver40ft', label: 'Yacht (over 40ft)' },
      { option: 'sailboat', label: 'Sailboat' },
      { option: 'catamaran', label: 'Catamaran' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Type',
      placeholderMessage: 'Select a boat type',
      isRequired: true,
      requiredMessage: 'You need to select a boat type.',
      tab: 'details',
      category: LISTING_BOAT,
    },
  },
  {
    key: 'boatMake',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Make',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Make',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Make',
      placeholderMessage: 'Enter the make of your boat',
      isRequired: false,
      requiredMessage: 'You need to enter a make of your boat',
      tab: 'details',
      isSimpleTextField: true,
      category: LISTING_BOAT,
    },
  },
  {
    key: 'boatModel',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Model',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Model',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Model',
      placeholderMessage: 'Enter the model of your boat',
      isRequired: false,
      requiredMessage: 'You need to enter a model of your boat',
      tab: 'details',
      isSimpleTextField: true,
      category: LISTING_BOAT,
    },
  },
  {
    key: 'boatYear',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Year',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Year',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Year',
      placeholderMessage: 'Enter the year of your boat',
      isRequired: false,
      requiredMessage: 'You need to enter a year of your boat',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_BOAT,
    },
  },
  {
    key: 'boatLength',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Length (ft)',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Length (ft)',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Length (ft)',
      placeholderMessage: 'Enter the length of your boat',
      isRequired: false,
      requiredMessage: 'You need to enter a length of your boat',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_BOAT,
    },
  },
  {
    key: 'boatPassengerCapacity',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Passenger Capacity',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Passenger Capacity',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Passenger Capacity',
      placeholderMessage: 'Maximum boat capacity',
      isRequired: true,
      requiredMessage: 'You need to enter a maximum boat capacity',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      maxLimit: 200,
      category: LISTING_BOAT,
    },
  },
  {
    key: 'numberOfCabinBedrooms',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: '# of Cabin Bedrooms',
      group: 'primary',
    },
    showConfig: {
      label: '# of Cabin Bedrooms',
      isDetail: true,
    },
    saveConfig: {
      label: '# of Cabin Bedrooms',
      placeholderMessage: 'Enter the number of Cabin Bedrooms',
      isRequired: false,
      requiredMessage: 'You need to enter a number of Cabin Bedrooms',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_BOAT,
    },
  },
  {
    key: 'boatCaptain',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'captain', label: 'Captain  (rental includes a captain)' },
      { option: 'noCaptain', label: 'No Captain (renter will operate vessel)' },
      { option: 'captainOptional', label: 'Captain Required (you will owe additional fees to the captain at time of departure)' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Captain',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Captain',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Captain',
      placeholderMessage: 'Will your vessel be captained?',
      isRequired: true,
      requiredMessage: 'You need to select a boat captain.',
      tab: 'details',
      category: LISTING_BOAT,
    },
  },
  {
    key: 'listingLocatedAt',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: locatedOptions,
    filterConfig: {
      indexForSearch: false,
      label: 'Boat is located at:',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat is located at:',
    },
    saveConfig: {
      label: 'Boat is located at:',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select at least one option.',
      tab: 'location',
      category: LISTING_BOAT,
    },
  },
  {
    key: 'pickupLocationDescription',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Pickup Location Description',
      group: 'primary',
    },
    showConfig: {
      label: 'Pickup Location Description',
      isDetail: false,
    },
    saveConfig: {
      label: 'Pickup Location Description',
      placeholderMessage: 'To ensure timely departures, please provide detailed location instructions for your renter to find the location of you rental experience.',
      isRequired: true,
      requiredMessage: 'You need to enter a pickup location description',
      tab: 'location',
      category: LISTING_BOAT,
    },
  },
  {
    key: 'dropOffLocationDescription',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Drop off Location Description (optional)',
      group: 'primary',
    },
    showConfig: {
      label: 'Drop off Location Description (optional)',
      isDetail: false,
    },
    saveConfig: {
      label: 'Drop off Location Description (optional)',
      placeholderMessage: 'If you are offering renters alternative drop off locations outside of the original pickup location, please describe where those drop off location options are in detail.',
      tab: 'location',
      category: LISTING_BOAT,
    },
  },
  {
    key: 'destinationDescription',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Destination Description',
      group: 'primary',
    },
    showConfig: {
      label: 'Destination Description',
      isDetail: false,
    },
    saveConfig: {
      label: 'Destination Description',
      placeholderMessage: 'Describe the destinations & sights renters will experience (i.e. Open Ocean, Sandbars, Mansion Tours, City Views, Islands, etc.)',
      tab: 'location',
      isRequired: true,
      requiredMessage: 'You need to enter a destination description',
      category: LISTING_BOAT,
    },
  },
  {
    key: 'listingAmenitiesBoat',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'boatAirConditioning', label: 'Air Conditioning' },
      { option: 'boatBathroom', label: 'Bathroom' },
      { option: 'boatBarbecueGrill', label: 'Barbecue Grill' },
      { option: 'boatBluetoothAudio', label: 'Bluetooth Audio' },
      { option: 'boatDishes', label: 'Dishes' },
      { option: 'boatChildrensLifeJackets', label: "Children's Life Jackets" },
      { option: 'boatCoolerIceChest', label: 'Cooler/Ice Chest' },
      { option: 'boatFloatingMat', label: 'Floating Mat' },
      { option: 'boatFishFinder', label: 'Fish Finder' },
      { option: 'boatFishingGear', label: 'Fishing Gear' },
      { option: 'boatFishingRodHolders', label: 'Fishing Rod Holders' },
      { option: 'boatFishingWell', label: 'Fishing Well' },
      { option: 'boatHeating', label: 'Heating' },
      { option: 'boatJacuzzi', label: 'Jacuzzi' },
      { option: 'boatKyakas', label: 'Kayaks' },
      { option: 'boatKitchen', label: 'Kitchen' },
      { option: 'boatLifeJackets', label: 'Life Jackets' },
      { option: 'boatMicrowave', label: 'Microwave' },
      { option: 'boatMusicSystem', label: 'Music System' },
      { option: 'boatPaddleboards', label: 'Paddleboards' },
      { option: 'boatPetsAllowed', label: 'Pets Allowed' },
      { option: 'boatRefrigerator', label: 'Refrigerator' },
      { option: 'boatShower', label: 'Shower' },
      { option: 'boatStovetop', label: 'Stovetop' },
      { option: 'boatSunbed', label: 'Sunbed' },
      { option: 'boatSunTop', label: 'Sun Top' },
      { option: 'boatStereoAUXInput', label: 'Stereo AUX Input' },
      { option: 'boatTender', label: '  Tender' },
      { option: 'boatToilet', label: 'Toilet' },
      { option: 'boatTv', label: 'TV' },
      { option: 'boatWaterSkis', label: 'Water Skis' },
      { option: 'boatWaterSlide', label: 'Water Slide' },
      { option: 'boatWaterTube', label: 'Water Tube' },
      { option: 'boatWakeboard', label: 'Wakeboard' },
      { option: 'boatWiFi', label: 'WiFi' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Amenities',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Amenities',
    },
    saveConfig: {
      label: ' ',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select at least one option.',
      tab: 'amenities',
      twoColumns: true,
      category: LISTING_BOAT,
    },
  },
]
