import { LISTING_FISHING } from '../util/types';

import { locatedOptions } from './configListingOptions'

export const fishingFields = [
  {
    key: 'fishingType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'fishingBoat', label: 'Fishing Boat' },
      { option: 'pontoon', label: 'Pontoon' },
      { option: 'yachtOver40ft', label: 'Yacht (over 40ft)' },
      { option: 'sailboat', label: 'Sailboat' },
      { option: 'catamaran', label: 'Catamaran' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Type',
      placeholderMessage: 'Select a boat type',
      isRequired: true,
      requiredMessage: 'You need to select a boat type.',
      tab: 'details',
      category: LISTING_FISHING,
    },
  },
  {
    key: 'boatMakeFishing',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Make',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Make',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Make',
      placeholderMessage: 'Enter the make of your boat',
      isRequired: false,
      requiredMessage: 'You need to enter a make of your boat',
      tab: 'details',
      isSimpleTextField: true,
      category: LISTING_FISHING,
    },
  },
  {
    key: 'boatModelFishing',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Model',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Model',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Model',
      placeholderMessage: 'Enter the model of your boat',
      isRequired: false,
      requiredMessage: 'You need to enter a model of your boat',
      tab: 'details',
      isSimpleTextField: true,
      category: LISTING_FISHING,
    },
  },
  {
    key: 'boatYearFishing',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Year',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Year',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Year',
      placeholderMessage: 'Enter the year of your boat',
      isRequired: false,
      requiredMessage: 'You need to enter a year of your boat',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_FISHING,
    },
  },
  {
    key: 'boatLengthFishing',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Length (ft)',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Length (ft)',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Length (ft)',
      placeholderMessage: 'Enter the length of your boat',
      isRequired: false,
      requiredMessage: 'You need to enter a length of your boat',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_FISHING,
    },
  },
  {
    key: 'fishingPassengerCapacity',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Passenger Capacity',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Passenger Capacity',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Passenger Capacity',
      placeholderMessage: 'Maximum boat capacity',
      isRequired: true,
      requiredMessage: 'You need to enter a maximum boat capacity',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_FISHING,
    },
  },
  {
    key: 'numberOfCabinBedroomsFishing',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: '# of Cabin Bedrooms',
      group: 'primary',
    },
    showConfig: {
      label: '# of Cabin Bedrooms',
      isDetail: true,
    },
    saveConfig: {
      label: '# of Cabin Bedrooms',
      placeholderMessage: 'Enter the number of Cabin Bedrooms',
      isRequired: false,
      requiredMessage: 'You need to enter a number of Cabin Bedrooms',
      tab: 'details',
      isSimpleTextField: true,
      onlyNumbersRequired: true,
      category: LISTING_FISHING,
    },
  },
  {
    key: 'fishingCaptain',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'captain', label: 'Captain  (rental includes a captain)' },
      { option: 'noCaptain', label: 'No Captain (renter will operate vessel)' },
      { option: 'captainOptional', label: 'Captain Required (you will owe additional fees to the captain at time of departure)' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'Boat Captain',
      group: 'primary',
    },
    showConfig: {
      label: 'Boat Captain',
      isDetail: true,
    },
    saveConfig: {
      label: 'Boat Captain',
      placeholderMessage: 'Will your vessel be captained?',
      isRequired: true,
      requiredMessage: 'You need to select a boat captain.',
      tab: 'details',
      category: LISTING_FISHING,
    },
  },
  {
    key: 'listingLocatedAtFishing',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: locatedOptions,
    filterConfig: {
      indexForSearch: false,
      label: 'Boat is located at:',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'Fishing is located at:',
    },
    saveConfig: {
      label: 'Boat is located at:',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select at least one option.',
      tab: 'location',
      category: LISTING_FISHING,
    },
  },
  {
    key: 'pickupLocationDescriptionFishing',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Pickup Location Description',
      group: 'primary',
    },
    showConfig: {
      label: 'Pickup Location Description',
      isDetail: true,
    },
    saveConfig: {
      label: 'Pickup Location Description',
      placeholderMessage: 'To ensure timely departures, please provide detailed location instructions for your renter to find the location of you rental experience.',
      isRequired: true,
      requiredMessage: 'You need to enter a pickup location description',
      tab: 'location',
      category: LISTING_FISHING,
    },
  },
  {
    key: 'dropOffLocationDescriptionFishing',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Drop off Location Description (optional)',
      group: 'primary',
    },
    showConfig: {
      label: 'Drop off Location Description (optional)',
      isDetail: true,
    },
    saveConfig: {
      label: 'Drop off Location Description (optional)',
      placeholderMessage: 'If you are offering renters alternative drop off locations outside of the original pickup location, please describe where those drop off location options are in detail.',
      tab: 'location',
      category: LISTING_FISHING,
    },
  },
  {
    key: 'destinationDescriptionFishing',
    scope: 'public',
    schemaType: 'text',
    filterConfig: {
      indexForSearch: false,
      label: 'Destination Description',
      group: 'primary',
    },
    showConfig: {
      label: 'Destination Description',
      isDetail: true,
    },
    saveConfig: {
      label: 'Destination Description',
      placeholderMessage: 'Describe the destinations & sights renters will experience (i.e. Open Ocean, Sandbars, Mansion Tours, City Views, Islands, etc.)',
      tab: 'location',
      isRequired: true,
      requiredMessage: 'You need to enter a destination description',
      category: LISTING_FISHING,
    },
  },
  {
    key: 'listingAmenitiesFishing',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'fishingAirConditioning', label: 'Air Conditioning' },
      { option: 'fishingBait', label: 'Bait' },
      { option: 'fishingBathroom', label: 'Bathroom' },
      { option: 'fishingBarbecueGrill', label: 'Barbecue Grill' },
      { option: 'fishingBluetoothAudio', label: 'Bluetooth Audio' },
      { option: 'fishingChildrensLifeJackets', label: "Children's Life Jackets" },
      { option: 'fishingCoolerIceChest', label: 'Cooler/Ice Chest' },
      { option: 'fishingFightingChair', label: 'Fighting Chair' },
      { option: 'fishingFilletStation', label: 'Fillet Station' },
      { option: 'fishingFishFinder', label: 'Fish Finder' },
      { option: 'fishingFishingGear', label: 'Fishing Gear' },
      { option: 'fishingFishingRods', label: 'Fishing Rods' },
      { option: 'fishingFishingRodHolders', label: 'Fishing Rod Holders' },
      { option: 'fishingFishingWell', label: 'Fishing Well' },
      { option: 'fishingGPSNavigation', label: 'GPS Navigation' },
      { option: 'fishingHeating', label: 'Heating' },
      { option: 'fishingKitchen', label: 'Kitchen' },
      { option: 'fishingLifeJackets', label: 'Life Jackets' },
      { option: 'fishingLivewell', label: 'Livewell' },
      { option: 'fishingMicrowave', label: 'Microwave' },
      { option: 'fishingMusicSystem', label: 'Music System' },
      { option: 'fishingRefrigerator', label: 'Refrigerator' },
      { option: 'fishingShower', label: 'Shower' },
      { option: 'fishingStereoAUXInput', label: 'Stereo AUX Input' },
      { option: 'fishingStovetop', label: 'Stovetop' },
      { option: 'fishingSunTop', label: 'Sun Top' },
      { option: 'fishingTender', label: 'Tender' },
      { option: 'fishingToilet', label: 'Toilet' },
      { option: 'fishingTv', label: 'TV' },
      { option: 'fishingWiFi', label: 'WiFi' },
    ],
    filterConfig: {
      indexForSearch: false,
      label: 'FISHING Amenities',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'FISHING Amenities',
    },
    saveConfig: {
      label: ' ',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select at least one option.',
      tab: 'amenities',
      twoColumns: true,
      category: LISTING_FISHING,
    },
  },
]
