import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconSortArrows.module.css';

const IconSortArrows = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        fill="#000"
        d="M9.028 10.678l-3.572 4.311V1.536a.338.338 0 00-.675 0V14.97l-3.572-4.293a.338.338 0 00-.52.432l4.179 5.02a.337.337 0 00.26.123c.1 0 .195-.045.26-.122l4.16-5.02a.338.338 0 00-.52-.432zm8.282-4.787L13.132.87a.338.338 0 00-.519 0l-4.16 5.02a.338.338 0 00.519.43l3.572-4.31v13.453a.337.337 0 10.675 0V2.03l3.572 4.293a.335.335 0 00.595-.184.337.337 0 00-.076-.247z"
      ></path>
    </svg>
  );
};

IconSortArrows.defaultProps = {
  rootClassName: null,
  className: null,
};

IconSortArrows.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSortArrows;
