import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './OrderBreakdown.module.css';

const LineItemCapacityMaybe = props => {
  const { passengerCapacity } = props;

  if(!passengerCapacity || isNaN(passengerCapacity) || +passengerCapacity === 0) return null

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.passengerCapacity" />
      </span>
      <span className={css.itemValue}>{passengerCapacity}</span>
    </div>
  )
};



export default LineItemCapacityMaybe;
